import Vue from 'vue'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_FORMALIZACION+'/v2');

export default {
  obtener_oficinas(id) {
    return http.get(`/empresas/${id}/oficinas`);
  }
}